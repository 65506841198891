<template>
    <div>
        <b-card no-body class="mb-1">
            <b-card-header>
                <strong class="mr-auto">فلترة حسب</strong>
                <b-button class="mr-1" @click="sendFilter" variant="primary"
                    >فلترة</b-button
                >
                <b-button @click="resetFilterDto()" variant="secondary"
                    >تهيئة</b-button
                >
            </b-card-header>
            <b-card-body class="pb-1">
                <b-row>
                    <b-col cols="12" md="6" lg="3">
                        <EKInputSelect
                            label="الكلية"
                            placeholder="اختر الكلية "
                            :options="[{ id: 0, name: 'الكل' }, ...faculties]"
                            name="select"
                            :clearable="true"
                            v-model="localeFilterDto.facultyId"
                        />
                    </b-col>
                    <b-col cols="12" md="6" lg="3">
                        <EKInputSelect
                            label="الفصل"
                            placeholder="اختر الفصل "
                            :options="[{ id: 0, name: 'الكل' }, ...semester]"
                            name="select"
                            :clearable="true"
                            v-model="localeFilterDto.semesterId"
                        />
                    </b-col>
                    <b-col cols="12" md="6" lg="3">
                        <EKInputSelect
                            label="المادة"
                            placeholder="اختر المادة "
                            :options="[
                                { id: 0, name: 'الكل' },
                                ...subjectsList
                            ]"
                            name="select"
                            :clearable="true"
                            v-model="localeFilterDto.subjectId"
                        />
                    </b-col>
                    <b-col cols="12" md="6" lg="3">
                        <EKInputSelect
                            label="سنة الدورة"
                            placeholder="اختر السنة "
                            :options="[{ id: 0, name: 'الكل' }, ...years]"
                            name="year"
                            :clearable="true"
                            v-model="localeFilterDto.examYear"
                        />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <EKTable
            :items="filterListCourses"
            :columns="columns"
            @details="openCourcesDetails"
            @delete-selected="fireDeleteEvent"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ new Date(value).toLocaleDateString("en-GB") }}
            </template>
        </EKTable>
    </div>
</template>
<script>
import EKTable from "@Ekcore/components/EK-table";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
    components: {
        EKTable,
        EKInputSelect
    },
    computed: {
        ...mapState({
            faculties: state => state.faculties.faculties,
            filterDto: state => state.filter.filterDto,
            subjectsList: state => state.subjects.subjectsList
        }),
        ...mapGetters(["courcesList", "filterListCourses", "semester", "years"])
    },
    data: () => ({
        localeFilterDto: {
            subjectId: 0,
            examYear: 0,
            semesterId: 0,
            facultyId: 0
        },
        columns: [
            {
                label: "اسم الدورة",
                field: "name"
            },
            {
                label: "عدد الأسئلة",
                field: "questionsCount",
                type: "number"
            },
            {
                label: "اسم المادة",
                field: "subjectName",
                sortable: false
            },
            {
                label: "تاريخ الإضافة",
                field: "dateCreated"
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false
            }
        ]
    }),

    created() {
        this.getCourcesList();
        this.getFacultiesDetails();
        this.fetchTotalTag();
        this.fetchSubject({ semesterId: "", year: "", facultyId: "" });
    },
    methods: {
        ...mapActions([
            "getCourcesList",
            "getFacultiesDetails",
            "fetchTotalTag",
            "fetchSubject",
            "deleteCourceList"
        ]),
        openCourcesDetails(props) {
            this.$router.push("/courses/" + props.row.id);
        },
        sendFilter() {
            this.$store.commit("FILTER_COURSES", this.localeFilterDto);
        },
        fireDeleteEvent(list) {
            this.deleteCourceList(list);
        },
        resetFilterDto() {
            Object.assign(this.localeFilterDto, {
                subjectId: 0,
                examYear: 0,
                semesterId: 0,
                facultyId: 0
            });
        }
    },
    beforeDestroy() {
        this.$store.commit("Reset_filter_Dto");
        this.$store.commit("Reset_Search_Dto");
    }
};
</script>
